import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 640 480",
  width: "24",
  height: "24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#1eb53a\" d=\"M0 320h640v160H0z\"></path><path fill=\"#0099b5\" d=\"M0 0h640v160H0z\"></path><path fill=\"#ce1126\" d=\"M0 153.6h640v172.8H0z\"></path><path fill=\"#fff\" d=\"M0 163.2h640v153.6H0z\"></path><circle cx=\"134.4\" cy=\"76.8\" r=\"57.6\" fill=\"#fff\"></circle><circle cx=\"153.6\" cy=\"76.8\" r=\"57.6\" fill=\"#0099b5\"></circle><g fill=\"#fff\" transform=\"matrix(1.92 0 0 1.92 261.1 122.9)\"><g id=\"uz-e\"><g id=\"uz-d\"><g id=\"uz-c\"><g id=\"uz-b\"><path id=\"uz-a\" d=\"M0-6-1.9-.3 1 .7\"></path><use href=\"#uz-a\" width=\"100%\" height=\"100%\" transform=\"scale(-1 1)\"></use></g><use href=\"#uz-b\" width=\"100%\" height=\"100%\" transform=\"rotate(72)\"></use></g><use href=\"#uz-b\" width=\"100%\" height=\"100%\" transform=\"rotate(-72)\"></use><use href=\"#uz-c\" width=\"100%\" height=\"100%\" transform=\"rotate(144)\"></use></g><use href=\"#uz-d\" width=\"100%\" height=\"100%\" y=\"-24\"></use><use href=\"#uz-d\" width=\"100%\" height=\"100%\" y=\"-48\"></use></g><use href=\"#uz-e\" width=\"100%\" height=\"100%\" x=\"24\"></use><use href=\"#uz-e\" width=\"100%\" height=\"100%\" x=\"48\"></use><use href=\"#uz-d\" width=\"100%\" height=\"100%\" x=\"-48\"></use><use href=\"#uz-d\" width=\"100%\" height=\"100%\" x=\"-24\"></use><use href=\"#uz-d\" width=\"100%\" height=\"100%\" x=\"-24\" y=\"-24\"></use></g>", 7)
  ])))
}
export default { render: render }