import { AxiosError } from 'axios';
import i18n from '@/shared/plugins/i18n';
import { ApiErrorResponse, IResponseData } from '@/shared/types';
import { toast } from '@/shared/utils/toast';
import { IApiError, IBaseApiErrorManager } from './managers.types';

export default function useApiCustomErrorMessageManager(): IBaseApiErrorManager {
  return {
    checkIfErrorMatchesTheResponse(
      error: AxiosError<IResponseData>
    ): boolean {
      return !!(error.config?.customErrorMapper);
    },

    async handleErrorResponse(
      error: AxiosError<IApiError>
    ): Promise<ApiErrorResponse> {
      const { response } = error;

      if (!response) return;

      const { message = '', statusCode = -1 } = response.data?.error || {};
      const errorMapper = error.config?.customErrorMapper || {};

      if (statusCode in errorMapper) {
        errorMapper[statusCode]();
      } else {
        toast.value?.error(message || i18n.global.t('Connection-error'));
      }
    }
  };
}
