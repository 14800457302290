export function pxToRem(px?: number | string) {
  if (!px) return '0rem';

  if (typeof px === 'string') {
    if (px.includes('rem')) {
      return px;
    }

    px = parseFloat(px);
  }

  const REM_VALUE = 10;

  return `${px / REM_VALUE}rem`;
}
