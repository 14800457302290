import { SelectMixedOption } from 'naive-ui/es/select/src/interface';
import { ICurrency } from './currency.types';

export const enum STATUS_TYPES {
  success = 'success',
  warning = 'warning',
  error = 'error',
  inactive = 'inactive'
}

export type PaymentDestinationCodeType = '1' | '2' | '3';

export const enum Tabs {
  MY_DOCUMENTS = 'my-documents',
  DOCUMENTS_IN_BANK = 'documents-in-bank',
  WAITING_DOCUMENTS = 'waiting-documents',
  DELETED_DOCUMENTS = 'deleted-documents',
  DEFAULT = 'default'
}

export const enum DocumentSource {
  BANK = 'bank',
  DBO = 'dbo',
  ABS = 'abs',
  NCI = 'nci',
  B2 = 'b2'
}

export interface TabMultiLevelApprove {
  name: Tabs;
  label: string;
  source?: DocumentSource;
  columns?: boolean;
  tabName?: Tabs;
}

export interface IPaymentDestinationCode {
  code: PaymentDestinationCodeType;
  name: string;
}

export type IReportSortType = IPaymentDestinationCode;
export type IReportFilters = IPaymentDestinationCode;

export interface IExchangeRate {
  currency: ICurrency;
  maxRate: number;
  minRate: number;
  order: string;
  flagUrl: string;
  kod: string;
  charKod: string | null;
  name: string;
  courseCb: string | null;
  changeCb: string | null;
  courseBuy: string;
  changeBuy: string;
  courseSell: string;
  changeSell: string;
}

export interface ICurrencyExchangeOrderStatusListParams {
  searchString: string | null;
  pageNumber: number;
  pageSize: number;
}

export interface ICurrencyExchangeOrderStatusItem {
  id: string | null;
  name: string | null;
}

export interface INonPaymentCipher{
  code: number,
  name: string
}

export interface INonPaymentCipherSelectData {
  items: SelectMixedOption[],
  totalPages: number
}

export interface IPaymentType{
  code: number,
  name: string
}

export interface IPaymentTypesSelectData {
  items: SelectMixedOption[],
  totalPages: number
}

export interface IPaymentCodesForUnpaidDocs{
  code: number,
  name: string
}

export interface IPaymentCodesForUnpaidDocsSelectData {
  items: SelectMixedOption[],
  totalPages: number
}

export interface IDefaultPaginatedPayloadData {
  searchString?: string | null;
  pageNumber?: number;
  pageSize?: number;
  InnOrPinfl?: string;
}

export interface SelectionData {
  id: number,
  value: number,
  label: string
}

export interface AppMenuOnboardingInfo {
  appVersion?: AppVersions;
  label?: string;
  description?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  videoMp4?: string;
  videoWebm?: string;
  videoPoster?: string;
  image?: string;
}

export const enum AppVersions {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3'
}
