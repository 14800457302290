import { StatusCodes } from '@/shared/types/api-status-codes.types';
import router from '@/app/router'; // TODO: из за этого импорта не работает тест (кажется не коррктное использую тут router)
import { useOnboardingStore } from '@/app/store/onboardingStore';
import { ROUTE_NAMES } from '../constants/router';

export default function customErrorMapperDOB() {
  return {
    [StatusCodes.techWorks]: async () => {
      const onboardingStore = useOnboardingStore();

      if (onboardingStore.isAuth) {
        await onboardingStore.signOut();
      }

      router.push({ name: ROUTE_NAMES.main, query: { techWorks: 'true' } });
    }
  };
}
