import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { InternalAxiosRequestConfig } from 'axios';
import i18n from '@/shared/plugins/i18n';
import { ApiVersions } from '@/shared/types';
import { LOCALES_API } from '@/shared/utils/constants/common';
import { useAuthStore } from '@/app/store/authStore';
import { useOnboardingStore } from '@/app/store/onboardingStore';

const defaultApiVersion = ApiVersions.v2;
let deviceId = '';

const baseUrls: Record<string, string> = {
  baseUrl: '/api/business/:businessCode/:branch',
  baseDepositUrl: '/api/deposit/:businessCode/:branch',
  baseMunisUrl: '/api/business/munis/payment/:businessCode/:branch',
  baseMunisDictionaryUrl: '/api/business/munis/dictionary/:businessCode/:branch'
};

export default async function useSuccessRequestInterceptor(
  request: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  const fpPromise = FingerprintJS.load();

  if (!deviceId) {
    const result = await (await fpPromise)?.get();
    deviceId = result.visitorId;
  }

  const controller = new AbortController();
  const authStore = useAuthStore();
  const onboardingStore = useOnboardingStore();

  if (!request.optionalAuth && (!authStore.accessToken && !onboardingStore.accessToken)) {
    controller.abort();
  }

  const branch = authStore.selectedBranchInfo?.branch || '0';
  const businessCode = authStore.selectedBranchInfo?.businessCode || '0';
  const partnerId = String(authStore.selectedBranchInfo?.partnerId) || '0';

  if (authStore.accessToken && !request.headers.Authorization)
    request.headers.Authorization = `Bearer ${authStore.accessToken}`;

  if (onboardingStore.accessToken && !request.headers.Authorization)
    request.headers.Authorization = `Bearer ${onboardingStore.accessToken}`;

  request.headers['Accept-Language'] = LOCALES_API[i18n.global.locale.value];
  request.headers['Content-Language'] = i18n.global.locale.value;
  request.headers['X-Device-Info'] = navigator.userAgent + ' ' + deviceId;
  request.headers['X-API-Version'] = request.apiVersion || defaultApiVersion;

  for (const key in baseUrls) {
    request.url = request.url?.replace(`:${key}`, baseUrls[key]);
  }

  request.url = request.url?.replace(':branch', branch);
  request.url = request.url?.replace(':businessCode', businessCode);
  request.url = request.url?.replace(':partnerId', partnerId);

  request.params = replaceSortingParams(request.params);

  return {
    ...request,
    signal: request.signal || controller.signal
  };
}

function replaceSortingParams(
  params:
    | { page?: string; pageSize?: string; sort?: string; order?: string }
    | undefined
    | null
) {
  if (!params?.order) return params;

  let slicingCount = 4;
  if (params?.order.startsWith('asc')) slicingCount = 3;

  return {
    ...params,
    order: params?.order.slice(0, slicingCount)
  };
}
