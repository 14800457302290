<script lang="ts" setup>
import { NIcon } from 'naive-ui';
import { pxToRem } from '@/shared/utils/styles';
import { IProps } from './base-icon.types';
import useBaseIcon from './useBaseIcon';

const props = withDefaults(defineProps<IProps>(), {
  color: 'inherit',
  size: '24'
});

const { SVGComponent, isSlot } = useBaseIcon(props);
</script>

<template>
  <n-icon
    :color="props.color"
    :size="pxToRem(props.size)"
    v-bind="$attrs"
    :style="{
      transform: props.scale ? `scale(${props.scale})` : undefined
    }"
  >
    <template v-if="isSlot">
      <slot />
    </template>
    <SVGComponent v-else />
  </n-icon>
</template>
