import { Router } from 'vue-router';
import { ApiDigitalSignature } from '@/shared/api';
import { CardProviders, DirectionType, IResponseData, ISignPayload, ISignResponse, SignAndSendData } from '@/shared/types';
import {
  DirectionSymbol,
  EDocumentType,
  IDocumentTemplateForm,
  IPaymentOrderTemplateParams
} from '@/shared/types/document.types';
import { IconName } from '@/shared/types/icon.types';
import { ESwiftTypes } from '@/shared/types/swift.types.ts';
import { PLATFORM_KEY_TYPE } from '@/shared/utils/constants/global.config.ts';
import { useAuthStore } from '@/app/store/authStore';
import { regExpStr } from '../validation-rules';

export function getProperDocumentType(documentType: EDocumentType) {
  const docType = {
    '01': 'account',
    '97': 'card',
    '98': 'exchequer',
    '99': 'budget'
  };

  return docType[documentType];
}

export function getProperSwiftType(swiftOperType: ESwiftTypes) {
  const swiftType = {
    '1': 'swift_import',
    '2': 'swift_guaranteed_loan',
    '3': 'swift_not_guaranteed_loan',
    '4': 'swift_repatriation',
    '5': 'swift_other',
    '6': 'swift_export',
    '7': 'swift_suspense_account'
  };

  return swiftType[swiftOperType];
}

export function getProperDateFormat(date: string) {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month =
    newDate.getMonth() < 10 ? '0' + newDate.getMonth() : newDate.getMonth();
  const day = newDate.getDay() < 10 ? '0' + newDate.getDay() : newDate.getDay();

  return `${year}-${month}-${day}`;
}

export function getFullDate(
  date: number | string | Date,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }
): string {
  return new Date(date).toLocaleDateString('ru', options).replace(',', '');
}

export function scrollToError(form: HTMLFormElement) {
  const el = form.querySelector('[class*="--error-status"]');

  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }
}

export function prepareDocumentTemplateData(
  v: IDocumentTemplateForm,
  paymentId?: string
): IPaymentOrderTemplateParams {
  const authStore = useAuthStore();

  const businessCode = authStore.selectedBranchInfo?.businessCode || '';
  const branch = authStore.selectedBranchInfo?.branch || '';

  return {
    paymentId,
    payload: {
      ...v.newTemplate,
      ...v.debit,
      ...v.credit,
      cardAccount: v.credit.cardAccount || null,
      receiverInnOrPinfl: v.credit.receiverInnOrPinfl || undefined,
      cardNumber: trimBetween(v.credit.cardNumber)?.split('-')?.[0] || null,
      budgetAccountNumber: v.credit.budgetAccountNumber || null,
      receiverBranch: v.credit.receiverBranch?.split('-')?.[0],
      ...v.others,
      paymentPurpose: removeAllLineBrakesAndNotAllowedSymbols(
        v.others.paymentPurpose
      ),
      senderBusinessCode: businessCode,
      senderBranch: branch,
      isAnor: false
    },
    documentType: v.newTemplate.documentType || EDocumentType.PaymentOrder,
    businessCode,
    branch
  };
}

export function pluralFunc(choice: number, choicesLength: number) {
  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (choicesLength < 4) {
    return !teen && endsWithOne ? 1 : 2;
  }
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
}

export function removeAllLineBrakesAndNotAllowedSymbols(
  str: string | null | undefined
) {
  return removeAllLineBrakes(removeAllNotAllowedSymbols(str));
}

export function removeAllLineBrakes(str: string | null | undefined) {
  if (!str) return null;

  return str.trimStart().replace(/\s+/g, ' ');
}

export function removeAllNotAllowedSymbols(str: string | null | undefined) {
  if (!str) return null;

  const regExpMapStr = [
    regExpStr.space.value,
    regExpStr.string.value,
    regExpStr.number.value,
    regExpStr.allowedSymbols.value
  ].join('|');

  const regExpObj = new RegExp(`(${regExpMapStr})`, 'gui');

  return str.match(regExpObj)?.join('') || '';
}

export function trimBetween(val?: string | null): string {
  if (!val) return '';
  const res = val.replace(/\s/g, '');
  if (isNaN(+res)) return res;
  return res;
}

export function removeExtraSpaces(val?: string | null): string {
  if (!val) return '';

  return val.replace(/\s+/g, ' ').trim();
}

export function removeBrackets(val?: string | null): string {
  return val?.replace(/\(|\)/giu, '') || '';
}

export function formatCardNumber(val?: string | null): string {
  if (!val) return '';

  return val.replace(/(.{4})/g, '$1 ');
}

export function maskCardNumber(val?: string | null): string {
  if (!val) return '';

  return `${val.slice(0, 4)} ${val.slice(4, 6)}∙∙ ∙∙∙∙ ${val.slice(-4)}`;
}

export function removeAllEmptyStringObjectProperties<T = {}>(data: T) {
  const newData = { ...data };

  const helper = (obj: T) => {
    Object.keys(obj as object).forEach((key) => {
      if (
        typeof obj[key as keyof T] === 'object' &&
        obj[key as keyof T] !== null
      ) {
        helper(obj[key as keyof T] as unknown as T);
      } else if (newData[key as keyof T] === '') {
        delete newData[key as keyof T];
      }
    });
  };

  helper(newData);

  return newData;
}

export function hasAtLeastOneFilterParam<T = {}>(filters: T): boolean {
  if (!filters && typeof filters !== 'object') return false;

  return Object.values(filters as object).some((v) => {
    if (typeof v === 'object' && v !== null) {
      return hasAtLeastOneFilterParam(v);
    }

    return v !== null && v !== '' && v !== undefined;
  });
}

export function setQueryParams<T = {}>(filters: T, router: Router): void {
  try {
    const filtersStr = encode(JSON.stringify(filters || '{}'));

    router.replace({
      query: {
        ...router.currentRoute.value.query,
        page: 1,
        filters: filtersStr
      }
    });
  } catch {
    return undefined;
  }
}

export function getQueryFilters<T = {}>(): T | null {
  const data = Object.fromEntries(
    new URL(window.location.toString()).searchParams.entries()
  );

  try {
    const filters = JSON.parse(
      decodeURIComponent(window.atob(data.filters)) || '{}'
    );
    return filters;
  } catch {
    return null;
  }
}

export function encode(str: string) {
  return window.btoa(encodeURIComponent(str));
}

export function getPasteText(event: ClipboardEvent): string {
  event.preventDefault();

  const clipboardData = event?.clipboardData || window?.clipboardData;

  if (!clipboardData) return '';

  const paste = clipboardData.getData('text');

  return paste;
}

export async function signAndSend<T extends object, U = ISignResponse>(
  itemsToSign: SignAndSendData[],
  requestToSign: (
    reqData: T & ISignPayload
  ) => Promise<IResponseData<U>>,
  requestParams?: Partial<T>
): Promise<IResponseData<U>> {
  const authStore = useAuthStore();
  const promises = itemsToSign.map((item) =>
    ApiDigitalSignature.signText(item.textToSign, authStore.eSign!)
  );
  const responses = (await Promise.all(promises)).filter(
    (response) => !response.error
  );

  const reqData = {
    keyType: PLATFORM_KEY_TYPE.WEB,
    objects: responses.map((response, index) => ({
      objectId: itemsToSign[index].id,
      textToSign: itemsToSign[index].textToSign,
      signature: response.signedMsg
    })),
    ...(requestParams || {})
  } as T & ISignPayload;

  return await requestToSign(reqData);
}

export function checkIsDayOff(date: string | number | Date, nonBusinessDays: string[]) {
  date = new Date(date);

  const localeDateString = date.toLocaleDateString('fr-CA');

  return nonBusinessDays.some(dateString => dateString.startsWith(localeDateString));
}

export function fixLineFeed(value: string) {
  return value.replace(/(\r\n|\r|\n)/g, '\r\n');
}

export function getWorkingAndHolidayDays(
  curDate: string | number | Date,
  nonBusinessDays: string[]
): number {
  const OPERATION_DAYS = 7;
  const ONE_DAY = 1;
  const date = new Date(curDate);

  let count = 0;
  let days = 0;

  while (count < OPERATION_DAYS) {
    date.setDate(date.getDate() + ONE_DAY);
    days++;

    if (checkIsDayOff(date, nonBusinessDays)) continue;

    count++;
  }

  return days;
}

export function formatAccountNumber(account: string) {
  const ACCOUNT_LENGTH = 20;
  const regExp = /^(\d{5})(\d{3})(\d)(\d{8})(\d{3})$/;
  const replacement = '$1 $2 $3 $4 $5';

  if (account.length !== ACCOUNT_LENGTH) return account;

  return account.replace(regExp, replacement);
}

export function getCardProviderByCardNumber(number: string | number): CardProviders {
  const numberString = number.toString();

  const numbersMap: { startString: string, provider: CardProviders }[] = [
    { startString: '8600', provider: CardProviders.uzcard },
    { startString: '5614', provider: CardProviders.uzcard },
    { startString: '9860', provider: CardProviders.humo },
    { startString: '60', provider: CardProviders.unionpay },
    { startString: '62', provider: CardProviders.unionpay },
    { startString: '4', provider: CardProviders.visa },
    { startString: '5', provider: CardProviders.mastercard }
  ];

  return numbersMap.find(({ startString }) => numberString.startsWith(startString))?.provider ?? CardProviders.other;
}

export function getCardIconByCardNumber(cardNumber: string | number): IconName {
  const inputIcons: Record<CardProviders, IconName> = {
    [CardProviders.uzcard]: 'uzcard-card',
    [CardProviders.humo]: 'humo-card',
    [CardProviders.visa]: 'visa-card',
    [CardProviders.mastercard]: 'mastercard-card',
    [CardProviders.unionpay]: 'unionpay-card',
    [CardProviders.other]: 'card-card'
  };

  return inputIcons[getCardProviderByCardNumber(cardNumber || '')];
}

export function getPaymentDirectionSymbol(direction?: DirectionType | null): DirectionSymbol {
  switch (direction) {
  case DirectionType.IN: return DirectionSymbol.IN;
  case DirectionType.OUT: return DirectionSymbol.OUT;
  default: return DirectionSymbol.BETWEEN;
  }
}
