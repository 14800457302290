import { RouteRecordRaw } from 'vue-router';
import { ACTION_TYPE, ROUTE_KEY_TYPE } from '@/shared/utils/constants/common';
import { ROUTE_NAMES } from '@/shared/utils/constants/router';

export default [
  {
    path: '/accounts-and-cards',
    children: [
      {
        path: '',
        name: ROUTE_NAMES.accountsCards.list,
        meta: {
          keyName: ROUTE_KEY_TYPE.ACCOUNTS,
          keyNameAction: ACTION_TYPE.VIEW
        },
        component: () => import('@/sections/accounts-and-cards/pages/AccountCardsListPage.vue')
      },
      {
        path: ':accountNumber/accounts',
        name: ROUTE_NAMES.accountsCards.accountDetails,
        meta: {
          keyName: ROUTE_KEY_TYPE.ACCOUNTS,
          keyNameAction: ACTION_TYPE.VIEW
        },
        component: () =>
          import('@/sections/accounts-and-cards/pages/AccountDetailsPage.vue')
      },
      {
        path: ':maskedCard/cards',
        name: ROUTE_NAMES.accountsCards.cardDetails,
        meta: {
          keyName: ROUTE_KEY_TYPE.ACCOUNTS,
          keyNameAction: ACTION_TYPE.VIEW
        },
        component: () =>
          import('@/sections/accounts-and-cards/pages/CardDetailsPage.vue')
      }
    ]
  }
] as RouteRecordRaw[];
