export const regExp = {
  string: /^[a-zA-Zа-яА-Я]/,
  latin: /^[a-zA-Z]/,
  int: /^\d+$/,
  latinMatch: /[a-zA-Z]/g,
  intMatch: /[0-9]/g,
  doubleMatch: /[0-9.]/g,
  email: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
  tag: /(<([^>]+)>)/gi,
  phone: /^\+[0-9]{3} [0-9]{2} [0-9]{3}-[0-9]{2}-[0-9]{2,3}$/,
  space: /\s/g,
  semver: /^\d+\.\d+\.\d+$/,
  passportSeries: /^[a-zA-Z]{2}$/,
  passportNumber: /^\d{7}$/,
  passport: /^[a-zA-Z]{2}\d{7}$/,
  hex: /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
} as const;
