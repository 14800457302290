import { GlobalThemeOverrides } from 'naive-ui';
import { COLORS } from '@/shared/config/colors';

export const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: '#6610f5',
    primaryColorHover: '#6610f5',
    primaryColorPressed: '#7000FF',
    borderRadius: '4px',
    errorColor: '#F84343'
  },
  Button: {
    borderRadiusLarge: '12px',
    borderDisabledPrimary: '#BBA2FD',
    colorDisabledPrimary: '#BBA2FD',
    colorPressedPrimary: '#7000FF',
    colorHoverPrimary: '#5B34C1',
    colorFocusPrimary: '#7000FF',

    colorFocusPressed: '#C5C7CA',
    colorSecondaryPressed: '#C5C7CA',
    colorSecondaryHover: '#E7E8EA',

    colorTertiaryPressed: '#101010',
    colorPressedTertiary: '#101010',
    colorTertiaryHover: '#4E4D4D',
    colorTertiary: '#101010',
    colorFocusTertiary: '#7000FF',
    colorTertiaryFocus: '#7000FF',
    textColorTertiary: '#fff',

    borderDisabled: '',
    color: '#F3F4F6',
    border: '#F3F4F6',
    colorHover: '#E7E8EA',
    borderHover: '#E7E8EA',
    textColorHover: '#101010',
    colorPrimary: '#6610f5',
    colorSecondary: '#F3F4F6',
    heightLarge: '48px',
    heightMedium: '40px',
    heightSmall: '32px',
    fontWeight: '600',
    fontSizeLarge: '16px',
    fontSizeMedium: '14px',
    fontSizeSmall: '14px',
    paddingLarge: '0 20px',
    iconSizeLarge: '24px',
    iconSizeMedium: '20px'
  },
  Dialog: {
    borderRadius: '2.4rem',
    padding: '3rem 4rem',
    titleFontSize: '2.8rem',
    titleFontWeight: 'bold',
    contentMargin: '8px 0 40px 0',
    fontSize: '1.8rem',
    lineHeight: '2.8rem'
  },
  Input: {
    borderFocus: '1px solid #6610f5',
    borderHover: '1px solid #6610f5',
    borderRadius: '12px',
    heightMedium: '48px'
  },
  InternalSelection: {
    borderRadius: '12px',
    heightMedium: '48px'
  },
  DatePicker: {
    borderRadius: '1.2rem',
    heightMedium: '4.8rem',
    panelBoxShadow: `0 0 0 1px ${COLORS.elementsQuaternary}`,
    panelBorderRadius: '1.6rem',
    calendarLeftPaddingDate: '.8rem 1.6rem .8rem',
    calendarRightPaddingDate: '.8rem 1.6rem .8rem',
    calendarDaysDividerColor: '#0000',
    panelActionPadding: '1.6rem 2.4rem',
    panelActionDividerColor: '#0000',
    panelHeaderDividerColor: '#0000',
    itemSize: '3.6rem',
    itemCellWidth: '4.2rem',
    itemCellHeight: '3.6rem',
    itemColorActive: COLORS.elementsPrimary,
    itemTextColorActive: COLORS.textQuaternary,
    itemTextColorCurrent: COLORS.staticPurple,
    itemTextColorDisabled: COLORS.textSecondary,
    itemTextColor: COLORS.textPrimary,
    itemBorderRadius: '.8rem',
    scrollItemBorderRadius: '.8rem',
    calendarTitleFontSize: '1.6rem',
    calendarTitleFontWeight: '600',
    calendarTitleTextColor: COLORS.textPrimary,
    arrowColor: COLORS.textPrimary,
    itemColorIncluded: COLORS.elementsTertiary,
    calendarDividerColor: '#0000'
  },
  Card: {
    borderRadius: '12px',
    paddingLarge: '48px 40px'
  },
  Radio: {
    buttonColorActive: '#6610f5',
    buttonTextColorActive: '#fff'
  },
  Form: {
    labelFontWeight: '500',
    lineHeight: '24px',
    labelFontSizeTopMedium: '16px',
    labelFontSizeLeftMedium: '16px'
  },
  Tabs: {
    barColor: '#000',
    tabTextColorLine: '#808080',
    tabTextColorActiveLine: '#000',
    tabTextColorHoverLine: '#000',
    tabFontSizeMedium: '16px',
    tabFontWeight: '600',
    tabFontWeightActive: '600'
  },
  Alert: {
    titleFontWeight: '600',
    fontSize: '1.6rem',
    colorError: '#f24835',
    titleTextColorError: '#fff',
    contentTextColorError: '#fff',
    borderError: 'none',
    borderRadius: '1.2rem',
    padding: '2.4rem',
    closeIconSize: '2rem',
    closeMargin: '20px 20px 0 0'
  },
  Notification: {
    iconColor: COLORS.textPrimary,
    lineHeight: '2rem',
    padding: '1.6rem',
    textColor: COLORS.textPrimary,
    titleFontSize: '1.6rem',
    titleFontWeight: '600',
    borderRadius: '1.6rem',
    headerTextColor: COLORS.textPrimary,
    headerFontWeight: '600',
    closeIconColor: COLORS.textPrimary,
    closeIconColorHover: COLORS.textPrimary,
    closeIconColorPressed: COLORS.textPrimary,
    closeColorHover: COLORS.transparent,
    closeColorPressed: COLORS.transparent,
    closeIconSize: '2.4rem',
    closeSize: '3.2rem',
    closeMargin: '1.6rem',
    closeBorderRadius: '100%',
    boxShadow: '0 1.2rem 2rem 0 #4453710D, 0 0 2rem 0 #4453711A',
    fontSize: '1.4rem',
    metaFontSize: '1.4rem',
    descriptionFontSize: '1.4rem',
    descriptionTextColor: COLORS.staticBlack,
    iconColorError: COLORS.staticRed,
    iconColorSuccess: COLORS.staticGreen,
    iconColorInfo: COLORS.staticBlack,
    iconColorWarning: COLORS.staticOrange,
    width: '48rem'
  }
};
