import { AxiosError } from 'axios';
import i18n from '@/shared/plugins/i18n';
import { ApiErrorResponse, IResponseData } from '@/shared/types';
import { toast } from '@/shared/utils/toast';
import { IApiError, IBaseApiErrorManager } from './managers.types';

const hideErrorMessageByCodes = (codes: number[], statusCode: number): boolean => {
  return codes.includes(statusCode);
};

export default function useApiMessageManager(): IBaseApiErrorManager {
  return {
    checkIfErrorMatchesTheResponse(error: AxiosError<IResponseData>): boolean {
      const errorCodesToHide = error.config?.errorCodesToHide || [];
      const response = error.response?.data;

      const statusCode = typeof response?.error === 'boolean'
        ? -1
        : response?.error?.statusCode!;

      return !error.config?.hideErrorMessage
        && !hideErrorMessageByCodes(errorCodesToHide, statusCode);
    },

    async handleErrorResponse(
      error: AxiosError<IApiError>
    ): Promise<ApiErrorResponse> {
      const { response } = error;

      if (response?.status === 401 || !response) return;

      const responseData = response?.data;
      const errorData = responseData?.error;

      const errorMessage: string | undefined =
        errorData?.message || responseData?.toString();

      const errors: [string, string[]][] = Object.entries(
        errorData?.params || []
      );

      if (errors?.length) {
        for (const err of errors) {
          if (err.length) {
            for (const e of err[1]) {
              toast.value?.error(e);
            }
          }
        }
      } else {
        setTimeout(() => {
          toast.value?.error(errorMessage || i18n.global.t('Connection-error'));
        }, 300);
      }
    }
  };
}
