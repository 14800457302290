import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useCommonStore = defineStore(
  'common',
  () => {
    const appVersion = ref('v1');

    const showStubModal = ref(false);
    const isShowHeaderStickyAlert = ref(false);
    const isInited = ref(false);
    const showUzumMarketBannerLast = ref(true);
    const showCounterpartyOnce = ref(true);
    const isSellerFormVisible = ref(false);

    const showSellerForm = () => {
      isSellerFormVisible.value = true;
    };

    const closeSellerForm = () => {
      isSellerFormVisible.value = false;
    };

    return {
      showStubModal,
      isShowHeaderStickyAlert,
      isInited,
      appVersion,
      showUzumMarketBannerLast,
      showCounterpartyOnce,
      isSellerFormVisible,
      showSellerForm,
      closeSellerForm
    };
  },
  {
    persist: {
      key: 'common',
      storage: window.localStorage,
      paths: [
        'appVersion',
        'showUzumMarketBannerLast',
        'showCounterpartyOnce'
      ]
    }
  }
);
