import { RouteRecordRaw } from 'vue-router';
import { ROUTE_KEY_TYPE } from '@/shared/utils/constants/common';
import { ROUTE_NAMES } from '@/shared/utils/constants/router';

export default [
  {
    path: '/contracts',
    name: ROUTE_NAMES.contracts.list,
    meta: {
      keyName: ROUTE_KEY_TYPE.CONTRACTS
    },
    component: () => import('@/sections/contracts/pages/contracts-list/ContractsListPage.vue')
  },
  {
    path: '/contracts/:id',
    name: ROUTE_NAMES.contracts.details,
    meta: {
      keyName: ROUTE_KEY_TYPE.CONTRACTS
    },
    component: () => import('@/sections/contracts/pages/contract-details/ContractDetailsPage.vue')
  }
] as RouteRecordRaw[];
